import { updateTokenInStore } from './tokenRefresher';
import { store } from '../store';

export const smx3AccessTokenSetter = async (searchParams: URLSearchParams) => {
    const token = searchParams.get('token');

    const smx3TokenUrl = `${window.location.protocol}//${window.location.hostname}/api/v1/auth/smx3`;

    const smx3AccessResponse = await fetch(smx3TokenUrl, {
        method: 'POST',
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
    });

    if (!smx3AccessResponse.ok) {
        return false;
    }

    const accessData = await smx3AccessResponse.json();
    updateTokenInStore(store, accessData.data);
    return true;
};
