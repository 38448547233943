import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessCheck, SmxPermissionContext, useAccess } from 'smx-components';

const IndexPage = () => {
    const navigate = useNavigate();
    const { permissionListScore } = useContext(SmxPermissionContext);

    useEffect(() => {
        if (accessCheck(permissionListScore, ['project'])) {
            navigate(`/projects`);
        } else if (accessCheck(permissionListScore, ['schedule-task', 'work-item'])) {
            navigate(`/schedule`);
        } else if (accessCheck(permissionListScore, ['files'])) {
            navigate(`/documents`);
        } else if (accessCheck(permissionListScore, ['user'])) {
            navigate(`/users`);
        }
    }, []);

    return <></>;
};

export default IndexPage;
