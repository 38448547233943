import { Calendar } from 'smx-components';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import React, { useRef } from 'react';
import { TasksImportModal, TasksImportModalHandles } from '../../../../components/tasksImportModal';

const WrapperCalendarTable = () => {
    const dispatch = useAppDispatch();

    const isFullScreen = useAppSelector((state) => state.schedule.isFullScreen);
    const user = useAppSelector((state) => state.account.user);
    const modalRef = useRef<TasksImportModalHandles>(null);

    const onFinishLoadData = () => {
        dispatch(setterSchedule({ isGantLoaded: true }));
    };

    return (
        <>
            <Calendar
                paddingBottom={!isFullScreen ? 40 : 0}
                activePage={'table'}
                authorId={user?.id || ''}
                onFinishLoadData={onFinishLoadData}
                onTasksImport={(args) => (modalRef.current ? modalRef.current.onTasksImport(args) : Promise.resolve())}
            />
            <TasksImportModal ref={modalRef} />
        </>
    );
};

export default WrapperCalendarTable;
