import { SmxDrawerPanel, Tabs, TabPane, AccessContainer } from 'smx-components';
import { Button, Flex } from 'antd';
import ButtonScale from './buttonScale';
import { CSSProperties } from 'react';
import ControlEditMode from './controlEditMode';
import WrapperGant from './WrapperGant';
import WrapperCalendar from './WrapperCalendar';
import useSwitchScheduleTabs from '../../../../hooks/useSwitchScheduleTabs';
import WrapperCalendarTable from './WrapperCalendarTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import { useAppDispatch } from '../../../../utilities/hooks';
import WrapperGantTable from './WrapperGantTable';
import WaitingSpinForLoadItem from './WaitingSpinForLoadItem';
import WrapperTaskBoard from './WrapperTaskBoard';

const SchedulePage = () => {
    const dispatch = useAppDispatch();

    const isFullScreen = useSelector<RootState, boolean>((state) => state.schedule.isFullScreen);
    const tab = useSwitchScheduleTabs();

    const stylePopup: CSSProperties = {
        backgroundColor: 'white',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1200,
        padding: 20,
    };

    const style: CSSProperties = { flex: 1, display: 'flex', flexDirection: 'row', minWidth: 0, minHeight: 0 };

    return (
        <div style={isFullScreen ? stylePopup : style}>
            <Flex vertical flex={1} style={{ minWidth: 0, height: '100%' }}>
                <Flex justify={'space-between'}>
                    <ControlEditMode />
                    <Button
                        icon={isFullScreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
                        onClick={() => dispatch(setterSchedule({ isFullScreen: !isFullScreen }))}
                    >
                        {isFullScreen ? 'Exit' : 'FullScreen'}
                    </Button>
                    <ButtonScale />
                </Flex>
                <Tabs tab={tab}>
                    <TabPane tabKey="0">
                        <AccessContainer accessFor={['work-item']} isAlert>
                            <WaitingSpinForLoadItem>
                                <WrapperGantTable />
                            </WaitingSpinForLoadItem>
                        </AccessContainer>
                    </TabPane>
                    <TabPane tabKey="1">
                        <AccessContainer accessFor={['schedule-task.view']} isAlert>
                            <WaitingSpinForLoadItem>
                                <WrapperCalendarTable />
                            </WaitingSpinForLoadItem>
                        </AccessContainer>
                    </TabPane>

                    <TabPane tabKey="2">
                        <AccessContainer accessFor={['work-item.view']} isAlert>
                            <WaitingSpinForLoadItem>
                                <WrapperGant />
                            </WaitingSpinForLoadItem>
                        </AccessContainer>
                    </TabPane>

                    <TabPane tabKey="3">
                        <AccessContainer accessFor={['schedule-task.view']} isAlert>
                            <WaitingSpinForLoadItem>
                                <WrapperCalendar />
                            </WaitingSpinForLoadItem>
                        </AccessContainer>
                    </TabPane>
                    <TabPane tabKey="4">
                        <WaitingSpinForLoadItem>
                            <WrapperTaskBoard />
                        </WaitingSpinForLoadItem>
                    </TabPane>
                </Tabs>
                <SmxDrawerPanel />
            </Flex>
        </div>
    );
};

export default SchedulePage;
