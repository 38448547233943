import { Calendar } from 'smx-components';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';

const WrapperCalendar = () => {
    const dispatch = useAppDispatch();
    const triggerForNewTask = useAppSelector((state) => state.schedule.triggerForNewTask);
    const user = useAppSelector((state) => state.account.user);

    useEffect(() => {
        if (triggerForNewTask) {
            dispatch(setterSchedule({ triggerForNewTask: undefined }));
        }
    }, [triggerForNewTask]);

    const onFinishLoadData = () => {
        dispatch(setterSchedule({ isGantLoaded: true }));
    };

    return (
        <Calendar
            paddingBottom={50}
            activePage={'schedule'}
            newTask={triggerForNewTask}
            authorId={user?.id || ''}
            onFinishLoadData={onFinishLoadData}
        />
    );
};

export default WrapperCalendar;
