import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SchedulePageViewType, ScheduleViewType } from '../types';
import { CalendarViewType, ItemTaskType, ScheduleItemType } from 'smx-components';
import { setInitialScheduleSliceWithURL } from '../routing/setInitialScheduleSliceWithURL';

export interface IScheduleState {
    activeCalendarPage: CalendarViewType;
    view: ScheduleViewType;
    triggerForNewTask?: ItemTaskType;
    triggerForNewItem?: ScheduleItemType;
    schedulePageView?: SchedulePageViewType;
    isGantLoaded: boolean;
    isFullScreen: boolean;
}

const initialState: IScheduleState = {
    activeCalendarPage: 'table',
    view: 'gant',
    schedulePageView: 'timeline',
    isGantLoaded: false,
    isFullScreen: false,
    ...setInitialScheduleSliceWithURL(),
};

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        setterSchedule: (state, action: PayloadAction<Partial<IScheduleState>>) => ({ ...state, ...action.payload }),
    },
});

export const { setterSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;
