import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SettingFragment$data } from 'smx-components/dist/cjs/src/graphql/__generated__/SettingFragment.graphql';
import { LoaderDataType } from 'smx-components/dist/cjs/src/types';

export interface IAppState {
    globalFilterProject: string[];
    globalFilterStatus?: number;
    dataSettingFromServer: LoaderDataType<SettingFragment$data>;
}

const initialState: IAppState = {
    globalFilterProject: [],
    dataSettingFromServer: { rows: [], count: 0, connectionId: '' },
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setterApp: (state, action: PayloadAction<Partial<IAppState>>) => ({ ...state, ...action.payload }),
    },
});

export const { setterApp } = appSlice.actions;

export default appSlice.reducer;
