import { Gant } from 'smx-components';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import React, { useRef } from 'react';
import { OnScheduleItemsImport } from 'smx-components/dist/esm/src/types';
import {
    ScheduleItemsImportModal,
    ScheduleItemsImportModalHandles,
} from '../../../../components/scheduleItemsImportModal';

const WrapperGantTable = () => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.account.user);
    const isFullScreen = useAppSelector((state) => state.schedule.isFullScreen);

    const modalRef = useRef<ScheduleItemsImportModalHandles>(null);

    const onFinishLoadData = () => {
        dispatch(setterSchedule({ isGantLoaded: true }));
    };

    return user ? (
        <>
            <Gant
                paddingBottom={!isFullScreen ? 20 : 0}
                mode={'table'}
                authorId={user?.id || ''}
                onFinishLoadData={onFinishLoadData}
                onScheduleItemsImport={(args) =>
                    modalRef.current ? modalRef.current.onScheduleItemsImport(args) : Promise.resolve()
                }
            />
            <ScheduleItemsImportModal ref={modalRef} />
        </>
    ) : null;
};

export default WrapperGantTable;
