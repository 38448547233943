import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ModalContext } from "../../providers/modalProvider";
import { ModalNamesType } from "slices/modalsSlice";
import CategoriesTreeModal from "modals/CategoriesTreeModal";
import ConfirmationModal from "modals/ConfirmationModal";
import DocumentVersionsModal from "modals/DocumentVersionsModal";
import GuestTeamModal from "modals/GuestTeamModal";
import RenameModal from "modals/RenameModal";
import TenantCreateModal from "modals/TenantCreateModal";
import ResolveUploadModal from "modals/ResolveUploadModal";
import HistoryModal from "modals/HistoryModal";
import CategoryCreateModal from "modals/CategoryCreateModal";
import DocumentMarkupModal from "modals/DocumentMarkupModal";

const ModalEntryPoint = () => {
    const { modalCallbacks } = useContext(ModalContext);
    const modals = useSelector((state: RootState) => state.modals);
    const modalKeys: ModalNamesType[] = Object.keys(modals) as ModalNamesType[];
    const modalComponents = {
        'confirmationModal': ConfirmationModal,
        'categoriesTreeModal': CategoriesTreeModal,
        'categoryCreateModal': CategoryCreateModal,
        'documentMarkupModal': DocumentMarkupModal,
        'documentVersionsModal': DocumentVersionsModal,
        'guestTeamModal': GuestTeamModal,
        'historyModal': HistoryModal,
        'renameModal': RenameModal,
        'resolveUploadModal': ResolveUploadModal,
        'tenantCreateModal': TenantCreateModal,
    };
    const [zIndexes, setZIndexes] = useState<Record<string, number>>({});

    useEffect(() => {
        const ids = new Set<string>();
        for (const modalKey of modalKeys) {
            for (const id of Object.keys(modals[modalKey])) {
                ids.add(id);
            }
        }

        for (const id of Object.keys(zIndexes)) {
            if (!ids.has(id)) {
                delete zIndexes[id];
            }
        }
    }, [modals]);

    const getZIndex = (id: string) => {
        if (zIndexes[id]) return zIndexes[id];

        const values = Object.values(zIndexes);
        const maxZIndex = values.length
            ? Math.max(...values)
                : 0;
        zIndexes[id] = maxZIndex + 1000;
        setZIndexes(zIndexes);
        return zIndexes[id];
    }

    return (
        <>
            {modalKeys.map((modalKey: ModalNamesType) => {
                return Object.keys(modals[modalKey]).map((id) => {
                    const ModalComponent = modalComponents[modalKey] as React.FC<any>;
                    return (
                        <ModalComponent
                            key={id}
                            id={id}
                            props={modals[modalKey][id]}
                            callbacks={modalCallbacks[modalKey][id]}
                            zIndex={getZIndex(id)}
                        />
                    );
                });
            })}
        </>
    );
}

export default ModalEntryPoint;
