import { Avatar, Badge, Flex, TableProps, Tooltip, Typography } from "antd";
import { FileExcelFilled, FileExcelOutlined, FileImageFilled, FileImageOutlined, FileOutlined, FilePdfFilled, FilePdfOutlined, FileWordFilled, FileWordOutlined, LockFilled, FolderOutlined } from '@ant-design/icons';
import WTypography from 'containers/WTypography';
import { Category, Document } from "interfaces/documents";
import { Chip } from "@mui/material";
import { useAppSelector } from "utilities/hooks";
import Moment from "react-moment";
import ItemActionList from "./ItemActionList";
import { User } from "interfaces/users";

const TableColumns = ({
    fetchData,
    shared = false,
    deleted = false,
}: {
    fetchData: () => void,
    shared?: boolean,
    deleted?: boolean,
}) => {
    const {
        teams,
        params,
    } = useAppSelector((state) => state.documents);

    var columns: TableProps<Document | Category>['columns'] = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            render: (_, record) => {
                if (record.type === 'category') {
                    return <Flex>
                        {
                            (record as Category).is_locked === true ?
                                <Badge
                                    count={
                                        <LockFilled
                                            style={{
                                                color: '#797979',
                                                fontSize: 12,
                                            }}
                                        />
                                    }
                                    offset={[0, 6]}
                                    className="badge-unset"
                                >
                                    <FolderOutlined />
                                </Badge>
                                :
                                <FolderOutlined />
                        }
                        <Tooltip title={record.name}>
                            <WTypography sx={{ pl: 2, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '150px' }}>{record.name}</WTypography>
                        </Tooltip>
                    </Flex>
                } else {
                    var icon = <FileOutlined />;
                    var document = record as Document;
                    if (document.extension === 'xlsx' || document.extension === 'xls') {
                        if (document.has_markup) icon = <FileExcelFilled />;
                        else icon = <FileExcelOutlined />;
                    } else if (document.extension === 'docx' || document.extension === 'doc') {
                        if (document.has_markup) icon = <FileWordFilled />;
                        else icon = <FileWordOutlined />;
                    } else if (document.extension === 'pdf') {
                        if (document.has_markup) icon = <FilePdfFilled />;
                        else icon = <FilePdfOutlined />;
                    } else if (document.extension === 'jpg' || document.extension === 'jpeg' || document.extension === 'png' || document.extension === 'gif' || document.extension === 'bmp' || document.extension === 'tiff' || document.extension === 'ico') {
                        if (document.has_markup) icon = <FileImageFilled />;
                        else icon = <FileImageOutlined />;
                    }
                    return <Flex>
                        {icon}
                        <Tooltip title={record.name}>
                            <WTypography sx={{ pl: 2, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '150px' }}>{record.name}</WTypography>
                        </Tooltip>
                        {document.version > 1 && <Chip label={`v${document.version}`} size="small" sx={{ ml: 1 }} />}
                    </Flex>
                }
            }
        },
        !deleted ? {
            key: 'team',
            dataIndex: 'team',
            title: 'Have Access',
            render: (_, row) => {
                if (!teams) return <></>;

                let _team = null;
                if (row.type === 'category') {
                    _team = teams[(row as Category).uuid];
                } else {
                    _team = teams[row.id];
                }
                if (!_team || !_team.members.length) return <></>;

                return <Avatar.Group max={{ count: 3 }}>
                    {_team.members.map((_member: User) => {
                        return <Tooltip
                            key={`m${_member.id}`}
                            title={_member.full_name}
                            placement="top"
                        >
                            <Avatar
                                alt={_member.full_name}
                                style={{ width: 32, height: 32, fontSize: 16 }}
                            >
                                {_member.initials}
                            </Avatar>
                        </Tooltip>;
                    })}
                </Avatar.Group>;
            }
        } : {},
        !deleted && !shared && (params?.query?.length || Object.keys(params?.filters || {}).length) ? {
            key: 'folder',
            dataIndex: 'folder',
            title: 'Folder',
            render: (_, row) => {
                if (row.type === 'document' && (row as Document).category_name) {
                    return <><Typography>{(row as Document).category_name}</Typography></>
                }
            }
        } : {},
        {
            key: 'size_formatted',
            dataIndex: 'size_formatted',
            title: 'Size',
        },
        !deleted ? {
            key: 'updated_at',
            dataIndex: 'updated_at',
            title: 'Last Modified',
            render: (_, row) => <Moment date={row.updated_at} fromNow />
        } : {},
        !deleted ? {
            key: 'updated_by',
            dataIndex: 'updated_by',
            title: 'Modified By',
            render: (_, row) => {
                if (!row.updated_by) return <></>;

                return <Tooltip
                    key={`u${row.updated_by.id}`}
                    title={row.updated_by.full_name}
                    placement="top"
                >
                    <Avatar
                        alt={row.updated_by.full_name}
                        style={{ width: 32, height: 32, fontSize: 16 }}
                    >
                        {row.updated_by.initials}
                    </Avatar>
                </Tooltip>;
            },
        } : {},
        deleted ? {
            key: 'deleted_at',
            dataIndex: 'deleted_at',
            title: 'Deleted',
            render: (_, row) => <Moment date={row.deleted_at} format="lll" />
        } : {},
        !deleted ? {
            render: (_, row) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <ItemActionList
                            fetchData={fetchData}
                            row={row}
                            shared={shared}
                        />
                    </div>
                )
            }
        } : {},
    ];
    columns = columns.filter((column) => Object.keys(column).length > 0);

    return columns;
}

export default TableColumns;
