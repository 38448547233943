import { Gant, ScheduleItemType } from 'smx-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';

const WrapperGant = () => {
    const dispatch = useAppDispatch();
    const triggerForNewItem = useSelector<RootState, ScheduleItemType | undefined>(
        (state) => state.schedule.triggerForNewItem,
    );
    const user = useAppSelector((state) => state.account.user);

    useEffect(() => {
        if (triggerForNewItem) {
            dispatch(setterSchedule({ triggerForNewItem: undefined }));
        }
    }, [triggerForNewItem]);

    const onFinishLoadData = () => {
        dispatch(setterSchedule({ isGantLoaded: true }));
    };

    return (
        <Gant
            paddingBottom={0}
            newItem={triggerForNewItem}
            mode={'chart'}
            authorId={user?.id || ''}
            onFinishLoadData={onFinishLoadData}
        />
    );
};

export default WrapperGant;
