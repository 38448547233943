export interface JwtToken {
    exp: number;
    p: string;
}

export const decodeToken = (token: string): JwtToken | null => {
    const parts = token.split('.');
    if (parts.length !== 3) {
        return null;
    }
    try {
        const payloadBase64 = parts[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
    } catch (err) {
        console.error(err);
        return null;
    }
};
