import type { RootState } from '../store';
import { setAccessToken } from '../slices/authSlice';
import { getApiUrl } from './axios';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { InternalAxiosRequestConfig } from 'axios';
import { Tenant } from '../interfaces/tenants';
import { decodeToken } from './tokenDecode';

export const updateTokenInStore = (store: ToolkitStore<RootState>, newToken: string): void => {
    const decoded = decodeToken(newToken);

    if (decoded && decoded.exp) {
        store.dispatch(
            setAccessToken({
                accessToken: newToken,
                accessTokenExpiresIn: decoded.exp,
            }),
        );
        localStorage.setItem('accessToken', newToken);
    }
};

const handleLoginIfNoToken = async (
    store: ToolkitStore<RootState>,
    config: InternalAxiosRequestConfig,
): Promise<string> => {
    const loginResponse = await fetch(config.url ?? '', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(config.data),
    });

    if (!loginResponse.ok) {
        const errorData = await loginResponse.json();
        const error = new Error(`Login failed: ${loginResponse.status}`);
        (error as any).response = {
            status: loginResponse.status,
            statusText: loginResponse.statusText,
            data: errorData,
            headers: loginResponse.headers,
        };
        throw error;
    }

    const loginData = await loginResponse.json();
    const newToken = loginData.data.access_token;
    if (!newToken) {
        throw new Error('No new token returned from login');
    }

    updateTokenInStore(store, newToken);

    // We already called login, so just simulate the response in axios interceptor
    config.adapter = async () => {
        return Promise.resolve({
            data: loginData,
            status: 200,
            statusText: 'OK',
            headers: config.headers,
            config,
            request: {},
        });
    };

    return newToken;
};

const refreshToken = async (currentTenant: Tenant | null): Promise<string> => {
    const refreshUrl = `${getApiUrl(currentTenant)}/api/v1/auth/refresh`;
    const refreshResponse = await fetch(refreshUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
    });

    if (!refreshResponse.ok) {
        const errorData = await refreshResponse.json();
        const error = new Error(`Refresh failed: ${refreshResponse.status}`);
        (error as any).response = {
            status: refreshResponse.status,
            statusText: refreshResponse.statusText,
            data: errorData,
            headers: refreshResponse.headers,
        };
        throw error;
    }

    const refreshData = await refreshResponse.json();
    return refreshData.data;
};

const isTokenFresh = (token: string, gap: number = 20): boolean => {
    const decoded = decodeToken(token);
    if (!decoded) {
        // If the token cannot be decoded, assume it is fresh
        return true;
    }
    const exp = decoded.exp;
    const now = Math.floor(Date.now() / 1000);
    return exp - now >= gap;
};

let refreshPromise: Promise<string> | null = null;

export async function tokenRefresher(
    store: ToolkitStore<RootState>,
    config?: InternalAxiosRequestConfig,
): Promise<string> {
    const state = store.getState();
    const storedToken = state.auth.accessToken;
    const { currentTenant } = state.tenants;

    if (config?.url?.endsWith('/login')) {
        const newToken = await handleLoginIfNoToken(store, config);
        return Promise.resolve(newToken);
    }

    if (!storedToken) {
        return Promise.resolve('');
    }

    if (isTokenFresh(storedToken)) {
        return Promise.resolve(storedToken);
    }

    // If a refresh is already in progress, return the same promise.
    if (refreshPromise) {
        return refreshPromise;
    }

    refreshPromise = (async () => {
        const newToken = await refreshToken(currentTenant);

        if (!newToken) {
            throw new Error('No new token returned from refresh');
        }

        updateTokenInStore(store, newToken);
        return newToken;
    })();

    // Clear the refreshPromise once it resolves or fails.
    refreshPromise
        .then(() => {
            refreshPromise = null;
        })
        .catch(() => {
            refreshPromise = null;
        });

    return refreshPromise;
}
