import { tokenRefresher } from '../utilities/tokenRefresher';
import { store } from '../store';

export const tokenRefreshMiddleware = async (args: any, next: any) => {
    const freshToken = await tokenRefresher(store);

    const { context } = args;

    context.request.headers = {
        ...context.request.headers,
        Authorization: `Bearer ${freshToken}`,
    };

    return next(args);
};
