import { Button, Modal, Skeleton } from "antd";
import { useAppDispatch } from "utilities/hooks";
import { closeModal } from "slices/modalsSlice";
import { ActivityLogsFeed } from "smx-components";
import { useCallback, useEffect, useState } from "react";

export interface IHistoryModalProps {
    id: string;
}

export interface IHistoryModalCallbacks {
    onClose?: () => void;
}

const HistoryModal = ({ id, zIndex, props, callbacks }: { id: string, zIndex: number, props: IHistoryModalProps, callbacks: IHistoryModalCallbacks }) => {
    const dispatch = useAppDispatch();
    const { onClose } = callbacks;
    const [fetchKey, setFetchKey] = useState(0);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (id) {
            setOpen(true);
            refreshData();
        }
    }, [id]);

    const refreshData = useCallback(() => {
        setFetchKey((prevKey) => prevKey + 1);
    }, []);

    const handleOnClose = () => {
        onClose?.();
        dispatch(closeModal({ modal: 'historyModal', id }));
    }

    return (
        <Modal
            zIndex={zIndex}
            title={'History'}
            open={open}
            onCancel={handleOnClose}
            width={800}
            footer={[
                <Button key="close" onClick={handleOnClose}>Close</Button>
            ]}
        >
            <ActivityLogsFeed model_id={props.id} isDragging={false} fetchKey={fetchKey} maxHeight={'75vh'} />
        </Modal>
    );
}

export default HistoryModal;
