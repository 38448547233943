import {ApiError} from "smx-components";

const KNOWN_ERROR_CODES: Record<string, string> = {
  'UNKNOWN_ERROR': 'Oops something went wrong. We are working on it.',
  'WRONG_PARAMETERS': 'Wrong arguments supplied to our backend.',
  'UNPROCESSABLE_ERROR': 'We could not process provided data.'
}
export const getErrorMessage = (error: unknown)=> {
  const fallback = KNOWN_ERROR_CODES['UNKNOWN_ERROR'];

  if (error instanceof ApiError) {
    return KNOWN_ERROR_CODES[error.code] ?? fallback;
  }

  return fallback;
}