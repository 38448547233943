import {
    Close as CloseIcon
} from '@mui/icons-material';
import {
    Avatar,
    FormControl,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { TeamMemberRoleOptions } from 'interfaces/teams';
import { User, Users } from 'interfaces/users';
import Moment from 'react-moment';
import { removeMember, updateMemberRole } from 'slices/teamSlice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const MembersList = () => {
    const {
        members,
        membersMap
    } = useAppSelector((state) => state.team);
    const { user } = useAppSelector((state) => state.account);
    const membersConstrained = user && [user.id];
    const dispatch = useAppDispatch();

    if (members?.length === 0) {
        return <></>;
    }

    return <List sx={{
        '& .MuiListItem-root .hidden': {
            visibility: 'hidden',
        },
        '& .MuiListItem-root:hover .hidden': {
            visibility: 'visible',
        },
    }}>
        {members?.map((member: User, index: number) => {
            const isConstrained = membersConstrained ? membersConstrained.includes(member.id) : false;

            return <ListItemButton key={index} disableGutters>
                <ListItem secondaryAction={
                    <IconButton
                        disabled={isConstrained}
                        edge="end"
                        className="hidden"
                        onClick={() => {
                            if (isConstrained) return;
                            dispatch(removeMember(member.id));
                        }}
                        sx={{ mr: 0 }}
                    >
                        <CloseIcon />
                    </IconButton>
                }>
                    <ListItemAvatar>
                        <Avatar>{member.initials}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<>
                            {member.name} <Typography className="hidden" variant="subtitle1" component="span">(<Moment fromNow>{membersMap[member.id]?.updated_at}</Moment>)</Typography>
                        </>}
                    />
                    <FormControl sx={{ minWidth: 120 }} disabled={isConstrained}>
                        <Select
                            size="small"
                            value={membersMap[member.id]?.role}
                            onChange={(event: SelectChangeEvent) => {
                                if (isConstrained) return;

                                const newRole = event.target.value;
                                dispatch(updateMemberRole({
                                    id: member.id,
                                    role: newRole,
                                }));
                            }}
                        >
                            {TeamMemberRoleOptions.map((option: any) => {
                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </ListItem>
            </ListItemButton>;
        })}
    </List>;
};

export default MembersList;