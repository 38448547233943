import { Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'utilities/hooks';
import { useEffect } from 'react';
import { smx3AccessTokenSetter } from '../../utilities/smx3AccessTokenSetter';

const LegacyAccessPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        (async () => {
            const isSet = await smx3AccessTokenSetter(searchParams);
            const redirectTo = searchParams.get('redirectTo') ?? '/';
            if (isSet) {
                navigate(redirectTo);
                window.location.reload();
            } else {
                localStorage.removeItem('accessToken');
                navigate('/signin');
                window.location.reload();
            }
        })();
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                Loading...
            </Box>
        </>
    );
};

export default LegacyAccessPage;
