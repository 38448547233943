import { Outlet, useLocation } from 'react-router-dom';
import { useSitemaxUIController } from 'context';
import { Box } from '@mui/material';

import WBox from 'containers/WBox';
import DefaultAppBar from 'components/DefaultAppBar';
import SideNavigation from 'components/SideNavigation';
import React, { Suspense, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { CalendarViewType } from 'smx-components';
import AppLoader from '../AppLoader';
import { useAppSelector } from '../utilities/hooks';

const TopLayout = () => {
    const [controller] = useSitemaxUIController();
    const location = useLocation();
    const { miniSidenav } = controller;
    const boxRef = useRef<HTMLDivElement>(null);
    const activeCalendarPage = useSelector<RootState, CalendarViewType>((state) => state.schedule.activeCalendarPage);
    const accessToken = useAppSelector((state) => state.auth.accessToken);

    useEffect(() => {
        if (boxRef.current && activeCalendarPage !== 'table') {
            boxRef.current.scrollTop = 0;
        }
    }, [activeCalendarPage]);

    return (
        <>
            {accessToken && (
                <Suspense fallback={''}>
                    <AppLoader />
                </Suspense>
            )}

            <SideNavigation />
            <WBox
                ref={boxRef}
                sx={({ breakpoints, transitions, functions: { pxToRem } }: any) => ({
                    p: 3,
                    position: 'relative',

                    overflowY: location.pathname.includes('schedule') ? 'hidden' : 'auto',
                    overflowX: 'hidden',
                    scrollBehavior: 'smooth',
                    webkitOverflowScrolling: 'touch',
                    webkitTouchCallout: 'none',
                    height: '100%',
                    minHeight: '100%',

                    // [breakpoints.up("xl")]: {
                    marginLeft: miniSidenav ? pxToRem(80) : pxToRem(274),
                    transition: transitions.create(['margin-left', 'margin-right'], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                    display: 'flex',
                    flexDirection: 'column',
                    // },
                })}
            >
                <DefaultAppBar parentRef={boxRef} />
                <WBox
                    sx={() => ({
                        mt: 3,
                        p: 3,
                        backgroundColor: '#FFFFFF', // NOTE: TEMP replace with proper theme variables
                        boxShadow: '0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)',
                        borderRadius: '1rem',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    })}
                >
                    <Box id="notification-bar"></Box>
                    <Outlet />
                </WBox>
            </WBox>
        </>
    );
};

export default TopLayout;
