import React from 'react';
import { Form, Upload, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

export interface CsvUploadFormItemProps {
    missingFileError?: string;
}

export const UploadFormItem: React.FC<CsvUploadFormItemProps> = ({
    missingFileError = 'Please select file to upload',
}) => {
    const form = Form.useFormInstance();
    const watchedFile = Form.useWatch('file', form);

    return (
        <Form.Item
            name="file"
            rules={[
                {
                    validator: async (_, value) => {
                        if (!value) {
                            return Promise.reject(new Error(missingFileError));
                        }
                        return Promise.resolve();
                    },
                },
            ]}
            style={{ height: 192, marginTop: 24 }}
        >
            <div style={{ textAlign: 'right' }}>
                <Upload
                    beforeUpload={() => false}
                    fileList={watchedFile ? [watchedFile] : []}
                    onChange={(info) => {
                        const [, newFile] = info.fileList;
                        form.setFieldValue('file', newFile);
                    }}
                >
                    <Button icon={<PlusOutlined />} type="primary" disabled={Boolean(watchedFile)}>
                        Upload
                    </Button>
                </Upload>
            </div>
        </Form.Item>
    );
};
