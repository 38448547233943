import { ProjectSelect, useRouting, ListItem, LIST_PROJECT_STATUS } from 'smx-components';
import { useAppDispatch } from '../../utilities/hooks';
import { setterApp } from '../../slices/appSlice';
import { Flex, Form } from 'antd';
import { useEffect } from 'react';

const FilterProjectBar = () => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const { setUrlParam, getParams } = useRouting();

    useEffect(() => {
        dispatch(
            setterApp({
                globalFilterProject: getParams().gplist ? (getParams().gplist as string)?.split(',') : undefined,
                globalFilterStatus: getParams().gstatus as number,
            }),
        );
    }, []);

    const projectSelectHandler = (value: string | string[]) => {
        setTimeout(() => {
            dispatch(setterApp({ globalFilterProject: value as string[] }));

            setUrlParam({ gplist: (value as string[]).join(',') });
        });
    };

    const statusSelectHandler = (value: number) => {
        setTimeout(() => {
            dispatch(setterApp({ globalFilterStatus: value, globalFilterProject: undefined }));

            form.setFieldsValue({ globalFilterProject: undefined });
            setUrlParam({ gstatus: value, gplist: undefined });
        });
    };

    return (
        <div style={{ width: '80%' }}>
            <Form
                form={form}
                // layout="horizontal"
                initialValues={{
                    globalFilterProject: getParams().gplist ? (getParams().gplist as string).split(',') : undefined,
                    globalFilterStatus: getParams().gstatus,
                }}
            >
                <Flex flex={1} justify={'start'} gap={5}>
                    <Form.Item label="" name={'globalFilterStatus'} style={{ width: '130px' }}>
                        <ListItem
                            style={{ flex: 1 }}
                            nameList={LIST_PROJECT_STATUS}
                            onChange={statusSelectHandler}
                            readonly
                        />
                    </Form.Item>
                    <Form.Item label="" name={'globalFilterProject'} style={{ width: 'calc(80% - 130px)' }}>
                        <ProjectSelect
                            style={{ width: '100%' }}
                            mode={'multiple'}
                            onChange={projectSelectHandler}
                            localFilters={getParams().gstatus ? { status: getParams().gstatus as number } : undefined}
                            maxTagCount={'responsive'}
                        />
                    </Form.Item>
                </Flex>
            </Form>
        </div>
    );
};

export default FilterProjectBar;
