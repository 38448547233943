import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/700.css';
import 'gridstack/dist/gridstack.min.css';
import {
    COUNT_RECORD_QUERY,
    LIST_COST_CODE,
    LIST_DIVISION,
    LIST_PHASE,
    LIST_TASK_GROUP_STATUS,
    LIST_TASK_STATUS,
    ListItemsQuery,
    ProjectQuery,
    RemoteSettingsQuery,
    ScheduleItemsQuery,
    SettingsQuery,
    TasksQuery,
    UsersShortQuery,
    TasksShortQuery,
    LoaderGraphQL,
} from 'smx-components';
import { useLazyLoadQuery, useRelayEnvironment } from 'react-relay';
import { useAppDispatch, useAppSelector } from './utilities/hooks';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { setterApp } from './slices/appSlice';

function AppLoader() {
    const dispatch = useAppDispatch();

    const { projects } = useAppSelector((state) => state.projects);
    const environment = useRelayEnvironment();
    const [fetchKeyUpdateItemsForChangeProjectList, setFetchKeyUpdateItemsForChangeProjectList] = useState<string>('');

    const { Setting: SettingLoader } = LoaderGraphQL();
    const settings = useLazyLoadQuery(SettingsQuery, {}, { fetchPolicy: 'network-only' });
    const ss = environment
        ?.getStore()
        .getSource()
        .get((settings as any).__id);

    // ToDo too many renders when scroll project page
    console.log('<======>');

    useLazyLoadQuery(RemoteSettingsQuery, {}, { fetchPolicy: 'network-only' });
    // useLazyLoadQuery(UsersShortQuery, { first: COUNT_RECORD_QUERY }, { fetchPolicy: 'network-only' });
    // useLazyLoadQuery(ProjectQuery, {}, { fetchPolicy: 'network-only' });
    // useLazyLoadQuery(
    //     ScheduleItemsQuery,
    //     { first: COUNT_RECORD_QUERY },
    //     { fetchPolicy: 'network-only', fetchKey: fetchKeyUpdateItemsForChangeProjectList },
    // );
    // useLazyLoadQuery(TasksShortQuery, {}, { fetchPolicy: 'network-only' });
    // // useLazyLoadQuery(TasksQuery, {}, { fetchPolicy: 'network-only' });
    //
    // useLazyLoadQuery(ListItemsQuery, { type: LIST_PHASE }, { fetchPolicy: 'network-only' });
    // useLazyLoadQuery(ListItemsQuery, { type: LIST_DIVISION }, { fetchPolicy: 'network-only' });
    // useLazyLoadQuery(ListItemsQuery, { type: LIST_TASK_GROUP_STATUS }, { fetchPolicy: 'network-only' });
    // useLazyLoadQuery(ListItemsQuery, { type: LIST_COST_CODE }, { fetchPolicy: 'network-only' });
    // useLazyLoadQuery(ListItemsQuery, { type: LIST_TASK_STATUS }, { fetchPolicy: 'network-only' });
    // useLazyLoadQuery(ListItemsQuery, { id: '00000000-0000-0000-0000-000000000000' }, { fetchPolicy: 'network-only' });

    useEffect(() => {
        setFetchKeyUpdateItemsForChangeProjectList(uuidv4());
    }, [projects]);

    return (
        <SettingLoader
            onUpdateSettingData={(rows: any[], count: number, connectionId: string) => {
                dispatch(setterApp({ dataSettingFromServer: { rows, count, connectionId } }));
            }}
            onUpdateRemoteSettingData={() => {}}
        />
    );
}

export default AppLoader;
