import { Button, Form, Input, Modal } from 'antd';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import {
    useEffect,
    useRef,
    useState
} from 'react';
import { createCategory } from 'slices/categoriesActions';
import { closeModal } from 'slices/modalsSlice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

export interface ICategoryCreateModalProps {
    parent?: number;
};

export interface ICategoryCreateModalCallbacks {
    onSubmit?: () => void;
    onClose?: () => void;
};

const CategoryCreateModal = ({ id, zIndex, props, callbacks }: { id: string, zIndex: number, props: ICategoryCreateModalProps, callbacks: ICategoryCreateModalCallbacks }) => {
    const { onSubmit, onClose } = callbacks;

    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector((state) => state.category);
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('Untitled Folder');

    useEffect(() => {
        if (id) {
            setIsOpen(true);
        }
    }, [id]);

    const handleClose = () => {
        onClose?.();
        close();
    };

    const handleSubmit = () => {
        dispatch(createCategory({
            name,
            parent_id: props.parent,
        }))
            .unwrap()
            .then(() => {
                onSubmit?.();
                close();
            }).catch(() => {});
    };

    const close = () => {
        dispatch(closeModal({ modal: 'categoryCreateModal', id }));
    };

    return (
        <Modal
            zIndex={zIndex}
            title={'Create folder'}
            open={isOpen}
            onCancel={handleClose}
            footer={
                <>
                    <Button
                        color="default"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        loading={loading}
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Create
                    </Button>
                </>
            }
        >
            <Form.Item
                validateStatus={error ? 'error' : ''}
                help={error?.errors?.name?.[0]}
            >
                <Input
                    id="name"
                    placeholder="Folder Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Form.Item>
        </Modal>
    );
};

export default CategoryCreateModal;