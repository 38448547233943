import { TaskBoard } from 'smx-components';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';

const WrapperTaskBoard = () => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.account.user);

    const onFinishLoadData = () => {
        dispatch(setterSchedule({ isGantLoaded: true }));
    };

    return (
        <TaskBoard paddingBottom={25} marginTop={25} onFinishLoadData={onFinishLoadData} authorId={user?.id || ''} />
    );
};

export default WrapperTaskBoard;
