import { decodeToken, JwtToken } from './tokenDecode';

export type PermissionListType =
    | 'files.view'
    | 'files.create'
    | 'files.edit'
    | 'files.manage'
    | 'files.project.view'
    | 'files.project.create'
    | 'files.project.edit'
    | 'files.project.manage'
    | 'users.view'
    | 'users.create'
    | 'users.edit'
    | 'users.manage'
    | 'projects.self.view'
    | 'projects.self.create'
    | 'projects.self.edit'
    | 'projects.self.manage'
    | 'projects.view'
    | 'projects.create'
    | 'projects.edit'
    | 'projects.manage'
    | 'work-item.view'
    | 'work-item.create'
    | 'work-item.edit'
    | 'work-item.manage'
    | 'work-item.owner.view'
    | 'work-item.owner.create'
    | 'work-item.owner.edit'
    | 'work-item.owner.manage'
    | 'work-item.assignee.view'
    | 'work-item.assignee.create'
    | 'work-item.assignee.edit'
    | 'work-item.assignee.manage'
    | 'schedule-task.view'
    | 'schedule-task.create'
    | 'schedule-task.edit'
    | 'schedule-task.manage'
    | 'schedule-task.assignee.view'
    | 'schedule-task.assignee.create'
    | 'schedule-task.assignee.edit'
    | 'schedule-task.assignee.manage'
    | 'schedule-task.owner.view'
    | 'schedule-task.owner.create'
    | 'schedule-task.owner.edit'
    | 'schedule-task.owner.manage'

type PermissionMapping = {
    [key: number]: string;
};

type ActionMapping = {
    [key: number]: string;
};

//should be sync with server PermissionAdapter.php
const groupPermissionByCode: PermissionMapping = {
    0: 'users',
    1: 'projects.self',
    2: 'projects',
    3: 'files',
    4: 'files.project',
    5: 'work-item',
    6: 'work-item.owner',
    7: 'work-item.assignee',
    8: 'schedule-task',
    9: 'schedule-task.owner',
    10: 'schedule-task.assignee',
};

const permissionMapping: ActionMapping = {
    1: 'view',
    2: 'create',
    4: 'edit',
    8: 'manage',
};

export class JwtPermissionAdapter {
    private permissions: Set<PermissionListType>;

    constructor(private token: string) {
        this.permissions = new Set(this.decodeToken(token));
    }

    private decodeToken(token: string): PermissionListType[] {
        const decoded: JwtToken | null = decodeToken(token);
        return decoded ? this.decodePermissions(decoded?.p?.split(',') || []) : [];
    }

    private decodePermissions(encodedPermissions: string[]): PermissionListType[] {
        const decodedPermissions: PermissionListType[] = [];

        for (const encoded of encodedPermissions) {
            const [groupIndex, value] = encoded.split(':').map(Number);

            if (groupIndex in groupPermissionByCode) {
                const group = groupPermissionByCode[groupIndex];

                for (const [bitValue, action] of Object.entries(permissionMapping)) {
                    if ((value & Number(bitValue)) !== 0) {
                        decodedPermissions.push(`${group}.${action}` as PermissionListType);
                    }
                }
            }
        }

        return decodedPermissions;
    }

    public getListPermissions() {
        console.log('this.permissions', this.permissions);
        return this.permissions;
    }

    public hasPermission(permission: PermissionListType): boolean {
        return this.permissions.has(permission);
    }
}
