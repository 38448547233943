import { Button, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../error/get-error-message';

export interface ImportTemplateButtonProps {
    downloadTemplate: () => Promise<void>;
}
export const ImportTemplateButton: React.FC<ImportTemplateButtonProps> = ({ downloadTemplate }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const [disabled, setDisabled] = useState(false);

    const downloadTemplateHandler = useCallback(async () => {
        try {
            setDisabled(true);
            await downloadTemplate();
        } catch (error: unknown) {
            notificationApi.error({
                message: getErrorMessage(error),
            });
        } finally {
            setDisabled(false);
        }
    }, []);

    return (
        <>
            {contextHolder}
            <Button type="link" disabled={disabled} onClick={downloadTemplateHandler}>
                Download template
            </Button>
        </>
    );
};
