import {
    createContext
} from 'react';
import { User } from './users';
import { Invitation } from './invitations';

export enum TeamMemberRole {
    NONE = 'none',
    VIEWER = 'viewer',
    EDITOR = 'editor',
    MANAGER = 'manager',
};

export const TeamMemberRoleOptions: Array<{ value: TeamMemberRole, label: string }> = [
    { value: TeamMemberRole.VIEWER, label: 'Viewer'},
    { value: TeamMemberRole.EDITOR, label: 'Editor'},
    { value: TeamMemberRole.MANAGER, label: 'Manager'},
];

export enum TeamType {
    CUSTOM = 'custom',
    GUEST = 'guest',
};

export interface Team {
    id: string;
    name: string;
    type: string;
    entity_id?: null | string;
    members?: User[];
    invitations?: Invitation[]
};

export interface Teams extends Array<Team> {};

export const TeamsContext = createContext<Team | null>(null);

export const EntityContext = createContext<any | null>(null); //  Document | Category

export const ProjectsContext = createContext<any | null>(null);