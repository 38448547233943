import { SchedulePageViewType } from '../types';
import { IScheduleState } from '../slices/scheduleSlice';

export const setInitialScheduleSliceWithURL = (): Partial<IScheduleState> => {
    const { pathname, search } = window.location;

    const [page, ...restUrl] = pathname.split('/').filter(Boolean);
    const viewFromUrl = restUrl.join('/');

    if (page !== 'schedule') {
        return {};
    }

    const urlMap: Record<string, SchedulePageViewType> = {
        table: 'list',
        'table/items': 'listItem',
        'table/tasks': 'listTask',
        calendar: 'schedule',
        'task-board': 'board',
    };

    return { schedulePageView: urlMap[viewFromUrl] ?? 'timeline' };
};
