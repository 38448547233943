import { Dropdown } from "antd";
import { MoreOutlined } from '@ant-design/icons';

import { Category, Document } from "interfaces/documents";
import { Delete } from "./ItemActions/Delete";
import { Copy } from "./ItemActions/Copy";
import { Move } from "./ItemActions/Move";
import { Rename } from "./ItemActions/Rename";
import { Versions } from "./ItemActions/Versions";
import { Sharing } from "./ItemActions/Sharing";
import { useAllowed } from "utilities/hooks";
import { History } from "./ItemActions/History";

const ItemActionList = ({
    fetchData,
    row,
    shared,
}: {
    fetchData: () => void,
    row: Category | Document,
    shared?: boolean,
}) => {
    const isAllowedManage = useAllowed(['files.manage']);
    const isAllowedEdit = useAllowed(['files.edit', 'files.manage']);

    const copyBtn = Copy(row, fetchData);
    const deleteBtn = Delete(row, fetchData);
    const historyBtn = History(row);
    const moveBtn = Move(row, fetchData);
    const renameBtn = Rename(row, fetchData);
    const sharingBtn = Sharing(row, fetchData);
    const versionBtn = Versions(row as Document);

    const items = [];

    if (shared) {
        if (!(row as Category).is_locked && isAllowedManage) {
            if (row.type === 'document') {
                items.push(versionBtn, copyBtn);
            }
            items.push(sharingBtn, deleteBtn, historyBtn);
        }
        if (!(row as Category).is_locked && isAllowedEdit) {
            items.push(renameBtn, moveBtn);
        }
    } else {
        if (isAllowedManage) {
            if (row.type === 'document') {
                items.push(versionBtn, copyBtn);
            }
            items.push(sharingBtn, deleteBtn, historyBtn);
        }
        if (isAllowedEdit) {
            items.push(renameBtn, moveBtn);
        }
    }

    return (
        <>
            <Dropdown menu={{ items }}>
                <a
                    onClick={(e) => { e.preventDefault() }}
                >
                    <MoreOutlined />
                </a>
            </Dropdown>
        </>
    );
}

export default ItemActionList;